import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Carousel, Card } from 'react-bootstrap';
import AppNavbar, { AppFooter } from './AppNavbar';
import YouTube from 'react-youtube';
import { FaInstagram, FaFacebookF, FaAt, FaWhatsapp } from "react-icons/fa";

function App() {
  const [videos, setVideos] = useState<any[]>();

  useEffect(() => {
    const apiKey = "AIzaSyC-hduY_vouft4rfEkCm7rcRLYe-bSiHmA";
    const channelId = "UCDGHEbZPpCvkFvyCfKZVFDg";
    async function getChannelVideos() {
      const response = await fetch(`https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=20`);
      if (response) {
        const data = await response.json();
        const videos = (data.items as any[]).filter(item => item.id.kind === "youtube#video");
        setVideos(videos);
      }
    }
    getChannelVideos();
  }, []);

  return (
    <div className="App">
      <header>
        {/* <AppNavbar /> */}
        <Container fluid>
          <Row>
            <Col className='text-center'>
              <Image fluid src={require('./assets/foto_03_head.jpg')} alt='Imagem de Jefferson (à esquerda) e Jonathan Lazzaro, integrantes da dupla BLDuo.' />
            </Col>
          </Row>
        </Container>
      </header>
      <main>
        <Container fluid>
          <Row className='d-flex justify-content-center mt-4 mb-2'>
            <Col className='text-center' md={6}>
              <Image src={require('./assets/logo_branco_colorido.png')} fluid width={400} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className='text-center mt-2 mb-3'>
              <Card bg='dark'>
                <Card.Body>
                  <p className='text-light'>
                    Bem-vindo ao site da dupla musical BLDuo, formada pelos irmãos
                    Jonathan (voz, teclados) e Jefferson Lazzaro (voz, violões).</p>
                  <p className='text-light'>
                    Em nosso site, você encontrará:</p>
                  <ul className='text-light'>
                    <li>Vídeos do nosso canal do YouTube</li>
                    <li>Nossas mídias sociais</li>
                    <li>Nossos dados de contato</li>
                    <li>... e muito mais!</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <h1 className='text-light text-center'>
                Últimos vídeos do canal
            </h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col md={6} className='text-center'>
              <Carousel interval={null}>
                {videos?.map(video => (
                  <Carousel.Item key={video.id.videoId}>
                    <YouTube videoId={video.id.videoId} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
          <br />
        </Container>
        <Container>
          <Row>
            <Col>
              <h1 className='text-light text-center'>
                Mídias sociais
            </h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col md={9} className='text-center mt-2 mb-3'>
              <Card bg='dark'>
                <Card.Header>
                  <p className='text-light'>
                    Clique em algum dos links abaixo, para ser levado à rede social desejada.
                  </p>
                  <p className='text-light'>
                    Logo abaixo você encontra o WhatsApp dos dois integrantes da dupla, para contato comercial.
                  </p>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={4} className='text-center'>
                      <h3 className='text-light'>
                        <FaInstagram />
                        <a className='text-light' target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/blmusicduo">&nbsp;Instagram</a>
                      </h3>
                    </Col>
                    <Col md={4} className='text-center'>
                      <h3 className='text-light'>
                        <FaFacebookF />
                        <a className='text-light' target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/blmusicduo">&nbsp;Facebook</a>
                      </h3>
                    </Col>
                    <Col md={4} className='text-center'>
                      <h3 className='text-light'>
                        <FaAt />
                        <a className='text-light' target='_blank' rel="noopener noreferrer" href="mailto:blmusicduo@gmail.com">&nbsp;E-mail</a>
                      </h3>
                    </Col>
                  </Row>
                  <Row className='d-flex justify-content-md-center mt-md-3'>
                    <Col md={4}>
                      <h3 className='text-light'>
                        <FaWhatsapp />
                        <a className='text-light' target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5569984040484&text=Olá,%20Jonathan!">&nbsp;WhatsApp (Jonathan)</a>
                      </h3>
                    </Col>
                    <Col md={4} className='text-center'>
                      <h3 className='text-light'>
                        <FaWhatsapp />
                        <a className='text-light' target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5569992327743&text=Olá,%Jefferson!">&nbsp;WhatsApp (Jefferson)</a>
                      </h3>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
      <footer>
        <AppFooter />
      </footer>
    </div>
  );
}

export default App;
