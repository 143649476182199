import * as React from 'react';
import { Navbar } from 'react-bootstrap';

const AppNavbar = () => {
    return (
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand href="#home">
                <img
                    alt=""
                    src={require('./assets/logo_branco.svg')}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />
                BLDuo
    </Navbar.Brand>
        </Navbar>
    )
}

export const AppFooter = () => {
    const date = new Date();

    return (
        <Navbar bg="dark" variant="dark" sticky='bottom'>
            <Navbar.Text>
                <small>&copy; {date.getFullYear()} BLDuo. Todos os direitos reservados.</small>
            </Navbar.Text>
        </Navbar>
    )
}

export default AppNavbar;